// @flow strict
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Feed from '../components/Feed';
import Page from '../components/Page';

import { Menu, Button, Header } from 'semantic-ui-react'

import { useSiteMetadata } from '../hooks';
import type { AllMarkdownRemark, PageContext } from '../types';

type Props = {
  data: AllMarkdownRemark,
  pageContext: PageContext
};

const TagTemplate = ({ data, pageContext }: Props) => {
  const { title: siteTitle, subtitle: siteSubtitle } = useSiteMetadata();

  const {
    tag,
    currentPage
  } = pageContext;

  const { edges } = data.allMarkdownRemark;
  const pageTitle = currentPage > 0 ? `All Posts tagged as "${tag}" - Page ${currentPage} - ${siteTitle}` : `All Posts tagged as "${tag}" - ${siteTitle}`;

  return (
    <Layout title={pageTitle} description={siteSubtitle}>
      <Page>
        <Header style={{marginBottom: '5px'}}>
          <Menu secondary>
            <img
              src={'/photo.jpg'}
              width="45"
              height="45"
            />
            <Menu.Item header href='/'>
              Smoke-Indica.com
            </Menu.Item>
          </Menu>
          <Button.Group compact style={{marginTop: '5px'}}>
            <Button href='/' icon='home' />
            <Button href='/pages/about'>About</Button>
            <Button href='/pages/related'>Related</Button>
            <Button href='/pages/stats'>Stats</Button>
            <Button href='/pages/explorers'>Explorers</Button>
          </Button.Group>
        </Header>
        <Header as='h1' style={{marginBottom: '5px'}}>
          Showing {edges.length} posts tagged with "{tag}"
        </Header>
        <Feed edges={edges} />
        <br/>
        <h3 style={{ textAlign: 'center', margin: '0px' }}>👋 Scroll within the box above 👆</h3>
      </Page>
    </Layout>
  );
};

export const query = graphql`
  query TagPage($tag: String, $postsLimit: Int!, $postsOffset: Int!) {
    site {
      siteMetadata {
        title
        subtitle
      }
    }
    allMarkdownRemark(
        limit: $postsLimit,
        skip: $postsOffset,
        filter: { frontmatter: { tags: { in: [$tag] }, template: { eq: "post" }, draft: { ne: true } } },
        sort: { order: DESC, fields: [frontmatter___date] }
      ){
      edges {
        node {
          fields {
            slug
            author
            net_votes
            total_payout_value
            images
            pending_payout_value
          }
          frontmatter {
            title
            date
            category
            sortvalue
            tags
          }
        }
      }
    }
  }
`;

export default TagTemplate;
