// @flow strict
import { Link } from 'gatsby';
import moment from 'moment';
import React from 'react';
import { isBrowser } from 'react-device-detect';
import { VariableSizeList as List } from 'react-window';
import { Button, Container, Item, Header, Image, Responsive } from 'semantic-ui-react'

import 'semantic-ui-css/semantic.min.css'

import type { Edges } from '../../types';
import styles from './Feed.module.scss';

type Props = {
  edges: Edges
};

function imgElement(imageSource, postSource, size) {
  return (
    <Image
      src={`https://images.weserv.nl/?url=${imageSource && imageSource.length ? imageSource[0] : 'https://www.smoke-indica.com/photo.jpg'}&w=${isBrowser ? 200 : 150}${size === 'large' ? '&h=380' : '&h=150'}${size === 'large' ? '&fit=inside' : '&fit=contain&cbg=transparent'}&output=webp`}
      as='a'
      size={size}
      href={postSource}
      alt='Main article image'
    />
  );
}

function extra(data, index) {
  return `${data[index].node.fields.net_votes} users rewarded @${data[index].node.fields.author} ${data[index].node.frontmatter.sortvalue} Smoke for this post.`;
}

const compactRow = ({ index, data, style }) => (
  <Container>
    <Item.Group divided='vertically' style={{
      ...style,
      backgroundColor: index % 2 === 0 ? 'white' : '#F5F5F5',
      borderBottom: '1px dotted black',
      maxWidth: '100%',
      marginRight: '0px',
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'center',
      overflow: 'auto',
      whiteSpace: 'break-wrap'
    }}>
      <Item>
        <Responsive minWidth={400}>
          <Item.Image>
            {imgElement(data[index].node.fields.images, data[index].node.fields.slug, 'medium')}
          </Item.Image>
        </Responsive>
          <Item.Content style={{paddingTop: '20px'}}>
            <Item.Header as='a' href={data[index].node.fields.slug} style={{wordWrap: 'break-word'}}>
                {data[index].node.frontmatter.title}
            </Item.Header>
            <Item.Meta>
              By <a href={`/${data[index].node.fields.author}`} style={{ color: 'green', fontWeight: 'bold' }}>@{data[index].node.fields.author}</a> on {moment(data[index].node.frontmatter.date).format('ll')}<br/>
            </Item.Meta>
            <Item.Description>
                {data[index].node.frontmatter.tags.map((tag) =>
                   <Button basic size='tiny' href={`/tag/${tag}`} className={styles['feed__compactButton']}>{tag}</Button>
                  )}
            </Item.Description>
            <Item.Extra>
              {extra(data, index)}
            </Item.Extra>
          </Item.Content>
      </Item>
    </Item.Group>
  </Container>
);

const imgRow = ({ index, data, style }) => {
  if (data[index].node.fields.images.length) {
    return (
      <Image.Group size='small' style={{
        ...style,
        backgroundColor: index % 2 === 0 ? 'white' : '#F5F5F5',
        borderBottom: '1px dotted black',
        width: '100%',
        marginRight: '0px',
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        overflow: 'auto',
        whiteSpace: 'nowrap'
      }}>
      {
        data[index].node.fields.images.map((img, imgIndex) => {
          if (imgIndex < 20) {
            return <Image
                as='a'
                href={data[index].node.fields.slug}
                style={{
                  paddingTop: '5px', paddingBottom: '5px', display: 'inline-block', minWidth: '150px', maxHeight: '100%', border: '1px dotted black'
                }}
                alt={`${data[index].node.fields.author} image ${imgIndex + 1} titled "${data[index].node.frontmatter.title}" and tagged "${data[index].node.frontmatter.tags.join(', ')}"`}
                title={`${data[index].node.fields.author} image ${imgIndex + 1} titled "${data[index].node.frontmatter.title}" and tagged "${data[index].node.frontmatter.tags.join(', ')}"`}
                src={`https://images.weserv.nl/?url=${img}&w=${isBrowser ? 200 : 150}&h=${isBrowser ? 180 : 150}&fit=contain&cbg=transparent&output=webp`}
              />;
          }
          return null;
        })
      }
    </Image.Group>);
  }
  return null;
};

function shuffle(a) {
  for (let i = a.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

function useHasMounted() {
  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
    setHasMounted(true);
  }, []);
  return hasMounted;
}

class SmokeFeedControl extends React.Component {
  constructor(props) {
    super(props);
    this.SetNormalViewClick = this.SetNormalViewClick.bind(this);
    this.SetImageViewClick = this.SetImageViewClick.bind(this);

    this.SetNewClick = this.SetNewClick.bind(this);
    this.SetMostRewardsClick = this.SetMostRewardsClick.bind(this);
    this.SetMostVotesClick = this.SetMostVotesClick.bind(this);
    this.SetShuffleClick = this.SetShuffleClick.bind(this);

    this.state = {
      viewType: 'compact',
      sortMethod: 'new'
    };
  }

  SetNewClick() {
    this.setState({sortMethod: 'new'});
  }

  SetMostRewardsClick() {
    this.setState({sortMethod: 'mostRewards'});
  }

  SetMostVotesClick() {
    this.setState({sortMethod: 'mostVotes'});
  }

  SetShuffleClick() {
    this.setState({sortMethod: 'shuffle'});
  }

  SetNormalViewClick() {
    this.setState({viewType: 'compact'});
  }

  SetImageViewClick() {
    this.setState({viewType: 'images'});
  }

  render() {
    return (
      <div>
        <Button.Group compact>
          <Button onClick={this.SetNewClick}>New</Button>
          <Button onClick={this.SetMostRewardsClick}>Rewarded</Button>
          <Button onClick={this.SetMostVotesClick}>Voted</Button>
          <Button onClick={this.SetShuffleClick}>Shuffled</Button>
        </Button.Group>
        <br />
        <Button.Group compact style={{marginTop: '5px'}}>
          <Button onClick={this.SetNormalViewClick}>Normal</Button>
          <Button onClick={this.SetImageViewClick}>Images</Button>
        </Button.Group>
        <SmokeFeed sortMethod={this.state.sortMethod} viewType={this.state.viewType} edgesInput={this.props.edgesInput} />
      </div>
    );
  }
}

function SmokeFeed(props) {
  const sortMethod = props.sortMethod;
  const viewType = props.viewType;
  const edges = props.edgesInput;

  let viewTypeContents;
  if (viewType === 'images') {
    viewTypeContents = imgRow;
  } else {
    viewTypeContents = compactRow;
  }

  let itemDataContents;
  if (sortMethod === 'new') {
    itemDataContents = [...edges].sort((a, b) => b.node.frontmatter.date - a.node.frontmatter.date);
  } else if (sortMethod === 'mostRewards') {
    itemDataContents = [...edges].sort((a, b) => b.node.frontmatter.sortvalue - a.node.frontmatter.sortvalue);
  } else if (sortMethod === 'mostVotes') {
    itemDataContents = [...edges].sort((a, b) => b.node.fields.net_votes - a.node.fields.net_votes);
  } else if (sortMethod === 'shuffle') {
    const referenceEdges = edges;
    itemDataContents = shuffle(referenceEdges);
  }

  /*
  itemSize={
    viewType === 'images'
      ? (index) => (edges[index].node.frontmatter.tags.length
        ? (edges[index].node.frontmatter.tags.length * 13) + (edges[index].node.frontmatter.title.length * 0.5) + 20
        : 150)
      : () => (
          isBrowser
            ? 400
            : 200
        )
  }
  */

  return (
    <div className={styles['feed__derp']}>
      <List
      height={isBrowser ? 750 : 650}
      itemCount={edges.length}
      rowCount={edges.length}
      itemData={itemDataContents}
      itemSize={() => (
        isBrowser
          ? 170
          : 170
      )}
      width={() => (isBrowser
        ? 300
        : 300)
      }
    >
      {viewTypeContents}
    </List>
  </div>)

}

const Feed = ({ edges }: Props) => {
  const hasMounted = useHasMounted();
  if (!hasMounted) {
    return null;
  }
  return (
    <SmokeFeedControl edgesInput={edges} />
  );
};

export default Feed;
